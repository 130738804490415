.producto_preview {
    border-radius: 20px;
    border-color: black;
    border: 1px;
    border-style: solid;
    margin: 10px;
    text-align: center;
    padding: 15px;
}
.producto_preview img{
    border-radius: 20px;
    overflow: hidden;
    width: 100%;
}
.producto_preview div{
    display: flex;
    justify-content: space-evenly;
}
@media only screen and (min-width: 425px){
    .producto_preview{
        margin: 10px 20px 10px 20px;
    }
    .producto_preview img{
        width: 350px;
    }
}
@media only screen and (min-width: 768px){
    .producto_preview{
        margin: 5px;
    }
    .producto_preview img{
        width: 100%;
    }
}