.Navegacion {
    position: sticky;
    top: 0;
    display: grid;
    grid-template-columns: 20% 60% 20%;
    z-index: 5;
    background-color: white;
    border-bottom: 2px black solid;
    height: 118px;
}
.Navegacion_Logo{
    grid-column: 2;
}
.Navegacion_Logo span{
    color: rgb(233, 13, 13);
}
.Navegacion .burger-menu{
    position: absolute;
    top: 36.44px;
    left: 10px;
    height: 40px;
    width: 40px;
    z-index: 6;
    margin: 4px;
    opacity: 0;
}
.Navegacion .burger-logo{
    position: relative;
    top: 36.44px;
    left: 10px;
    margin: 4px;
}
.Navegacion_nav{
    display: none;
    grid-column: 1 / 4;
    text-align: center;
}
.Navegacion input:checked ~.Navegacion_nav{
    display: grid;
}
.Navegacion_nav-item{
    text-decoration: none;
    color: black;
}
@media only screen and (min-width: 375px){
    .Navegacion .burger-menu{
        left: 20px;
    }
    .Navegacion .burger-logo{
        left: 20px;
    }
}
@media only screen and (min-width: 768px){
    .Navegacion{
        height: 83px;
    }
    .Navegacion .burger-menu{
        top: 10px;
        left: 50px;
    }
    .Navegacion .burger-logo{
        top: 10px;
        left: 50px;
    }
}
@media only screen and (min-width: 1024px){
    .Navegacion{
        padding-left: 5%;
        padding-right: 5%;
        grid-template-columns:50px auto 50px;
        text-align: center;
        height: 118px;
    }
    .Navegacion .burger-menu{
        display: none;
    }
    .Navegacion .burger-logo{
        display: none;
    }
    .Navegacion_nav{
        display: grid;
        grid-column: 2;
        grid-row: 2;
        display: inline-block;  
    }
    .Navegacion_nav-item{
        padding-left: 5px;
        padding-right: 5px;
    }
}