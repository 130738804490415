.AboutUs-text {
    text-align: justify;
    margin: 15px;
    max-width: 900px;
}
@media only screen and (min-width: 768px){
    .AboutUs-text {
        margin: 15px 50px;
    }
}
@media only screen and (min-width: 1024px){
    .AboutUs-text {
        margin: 15px auto;
    }
}
