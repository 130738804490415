.productModal-container{
    position: absolute;
    z-index: 2;
    top: 120px;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.5);
    text-align: center;
}
.productModal{
    margin: 50px 10px;
    background-color: white;
    padding: 5px;
    border: 2px solid gray;
    border-radius: 20px;
}
.productModal-description{
    text-align: justify;
}
@media only screen and (min-width: 768px){
    .productModal-container{
        top: 85px;
    }
    .productModal{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin: 50px 30px;
        padding: 25px;
    }
    .productModal-description{
        grid-column: 1 / 3;
    }
}
@media only screen and (min-width: 1024px){
    .productModal-container{
        top: 120px;
    }
    .productModal{
        margin: 50px 50px;
        background-color: white;
    }
}