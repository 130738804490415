.HowBuy-list {
    text-align: justify;
    margin: 15px;
    max-width: 800px;
}
.HowBuy-list li{
    margin-bottom: 10px;
}
@media only screen and (min-width: 768px){
    .HowBuy-list {
        margin: 15px 50px;
    }
}
@media only screen and (min-width: 1024px){
    .HowBuy-list {
        margin: 15px auto;
    }
}