.AdminModal_container{
    position: absolute;
    z-index: 2;
    top: 120px;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.5);
    text-align: center;
}
.AdminModal{
    margin: 50px auto;
    width: 500px;
    background-color: white;
    padding: 5px;
    border: 2px solid gray;
    border-radius: 20px;
}
.AdminModal form{
    display: grid;
    grid-template-columns: 100px 1fr;
}
.AdminModal form label{
    margin: 5px 0;
}
.AdminModal form input{
    margin: 5px 0;
}
.AdminModal form textarea{
    width: 394px;
    resize: vertical;
}
.AdminModal form button{
    margin: 5px 25px;
    height: 25px;
    background-color: white;
    border-radius: 5px;
}
.buttonRed{
    border-color: red;
    color: red;
}
.buttonRed:hover{
    border-color: grey;
    color: black;
    background-color: rgb(255, 0, 0);
}
.buttonGreen{
    color: green;
    border-color: green;
}
.buttonGreen:hover{
    border-color: grey;
    color: black;
    background-color: greenyellow;
}
