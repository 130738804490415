.Carro {
    text-align: center;
    display: flex;
    align-items: center;
}
.Carro_check{
    position: absolute;
    height: 60px;
    width: 60px;
    opacity: 0;
    z-index: 6;
}
.Carro_img {
    height: 50px;
}
.Carro_len{
    position: relative;
    left: -15px;
    top: 15px;
    height: 20px;
    min-width: 20px;
    background-color: white;
    border: 2px solid black;
    border-radius: 10px;
}
.Carro_modal{
    display: none;
}
.Carro input:checked ~.Carro_modal{
    display: grid;
    grid-template-columns: 150px 70px;
    position: absolute;
    background-color: white;
    top: 125px;
    right: 10px;
    border: 1px solid black;
    border-radius: 15px;
    padding: 10px;
}
.Carro_modal p{
    margin: 5px auto;
}
.Carro_link{
    text-decoration: none;
    color: black;
}