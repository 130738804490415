.Contact-form{
    display: flex;
    flex-direction: column;
    margin: 10px;
}
.Contact-wsp{
    text-align: center;
}
.wsp-button{
    height: 50px;
    width: 50px;
    background-image: url("../resources/wspLogo.png");
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    border-bottom-right-radius: 25px;
    border: 0;
    cursor: pointer;
}

@media only screen and (min-width: 768px){
    .Contact-form{
        max-width: 300px;
        margin: 10px auto;
    }
}